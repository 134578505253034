*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

:root {
  touch-action: pan-x pan-y;
  height: 100% 
}

body {
  height: 100vh;
  width: 100%;
  background-color: #fff;
}
.page--material__background{
  background-color:  #FEF4DD !important;
}
.page__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FEF4DD;
}
.main-container{
  background-color: #fc6 !important;
}
.main-logo{
  width:5rem;
  height:5rem;
}
.camera-border {
  margin: 4rem 0;
  /* background-color: white;
  display: flex;
  align-items: center;
  justify-content: center; */
}
.main-image{
  width:30rem;
  /* padding: 1rem 2.5rem 1rem 2.5rem */
  /* height:30rem; */
}
.main-text{
  font-family: 'Cinzel Decorative', cursive !important;
  text-align: center;
  font-size: 2rem !important;
  font-weight: 700 !important;
  margin-top: 4rem !important;
  line-height: 1.5;
  
}
.main-text span{
  font-family: 'Cinzel Decorative', cursive !important;
  text-align: center;
  font-size: 2rem !important;
  font-weight: 700 !important;
  margin-top: 4rem !important;
  line-height: 1.5;
  color:#ED1C24;
}
.main-text-2{
  width:70%;
  text-align: center;
  font-size: 1.5rem !important;
  line-height: 1.5;
  margin-bottom: 3rem !important;
  font-family: 'Open Sans', sans-serif !important;
}
.image{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.screen-container, #drawing-container,
#photo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.screen-container{
  background-color: #fc6;
  transition: 0.5s;
  left:100%;
}
.screen-container.isStart{
  background-color: #FEF4DD;
  left:0;
  overflow-x: hidden;
}
#photo-container, #drawing-container{
  background-color: #FEF4DD;
  transition: 0.5s;
}
video {
  /* width: 200px;
  height: 200px; */
  /* width: 100%;
  max-width: 100%; */
  /* height: auto; */
  margin:5rem 0;
  display: none;
}
.camera {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#timer {
  width: 8rem;
  height: 8rem;
  text-align: center;
  font-size: 3rem;
  color: #fff;
  background-color: black;
  position: absolute;
  top: 32rem;
  border-radius: 50%;
  padding-top: 1.5rem;
  opacity: 0.5;
  display: none;
}
.result {
  position: fixed;
  top: 0;
  left: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FEF4DD;
  transition: 0.4s;
}
.result.hasPhoto {
  left: 0;
}
.round-button {
  height:6rem;
  width:6rem;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border-radius: 50%;
  border: 1rem solid #ED1C24;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #ED1C24;
}
.back-selfie-button {
  height:8rem;
  width:8rem;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border-radius: 50%;
  border: 1rem solid #fc6;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #fc6;
  /* margin-top: 3rem; */
}
.icon-button {
  height:5rem;
  width:5rem;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border-radius: 50%;
  border: 1rem solid #F8DAC5;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.5s;
  background-color: #F8DAC5;
}
.start-round-button {
  height:10rem !important;
  width:10rem !important;
  border-radius: 50%;
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  text-align: center !important;
  font-size: 2rem !important;
  color: #fff !important;
  background-color: #ED1C24 !important;
  border: none !important;
  transition: all 0.5s;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom: 2rem;
}
.input-wrapper h6 {
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
input[type="text"],
input[type="email"]{
  width: 90%;
  font-size: 1.5rem;
  padding: 1.5rem;
  margin-top: 1.5rem;
  background-color: #F8DAC5 !important;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #F8DAC5;
  outline: none;
  border-radius: 50px;
}
.image-wrapper{
  width: 90%;
  margin: 4rem 0;
  position: relative;
}
.button-wrapper {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7rem;
}
.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.print-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12rem;
}
.print-wrapper.justifyCenter{
  justify-content: center !important;
}
.close-button{
  width:100%;
  font-size: 2rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 2.2rem !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FEF4DD !important;
  background-color: #ED1C24 !important;
  border-radius: 50px !important;
  transition: all 0.5s;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #ED1C24
}
.main-start-button{
  width:90%;
  font-size: 1.5rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 1.2rem !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FEF4DD !important;
  background-color: #ED1C24 !important;
  border-radius: 50px !important;
  transition: all 0.5s;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #ED1C24;
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  from { transform: scale(1); }
  50% { transform: scale(0.85); }
  to { transform: scale(1); }
}

.button{
  font-size: 1.5rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 1.2rem !important;
  outline: none;
  display: flex !important;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border-radius: 50px !important;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.5s;
}

.button.left{
  width:48%;
  color:#000 !important;
  background-color: #fc6 !important;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #fc6;
  outline: none;
  border-radius: 50px;
  transition: all 0.5s;
}
.button.right{
  width:48%;
  color:#FEF4DD !important;
  background-color: #ED1C24 !important;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #ED1C24;
  outline: none;
  border-radius: 50px;
  transition: all 0.5s;
 
}
.email-button{
  width:20%;
  color:#FEF4DD !important;
  background-color: #fc6 !important;
  border: 1rem solid #fc6;
  outline: none;
  transition: all 0.5s;
  font-size: 2rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 2.2rem !important;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border-radius: 50px !important;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.5s;
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
}
/* input[type="email"]{
  margin-top: 0 !important;
} */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #d5d5d5;
  cursor: pointer;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  font-size: 1.5rem;
  font-weight: bold;
}

input[type="checkbox"]:after {
  content: "\2714";
  font-size: 1.5rem;
  color: white;
  display: none;
}

input[type="checkbox"]:checked {
  background-color: #ED1C24;
}

input[type="checkbox"]:checked:after {
  display: block;
}
.submit-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
}
.form-wrapper{
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  margin-bottom:2.5rem;
}
.swal2-popup {
  width: 35em !important;
  padding: 0 0 2em !important;
  border-radius: 1em !important;
  font-size: 1rem !important
}
.swal2-title {
  font-size: 2rem !important;
}
.swal2-html-container {
  font-size: 1.5rem !important;
}
.swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
  background-color: #ED1C24 !important;
  font-size: 1.5rem !important;
}
.button.right.disabled, .button.left.disabled{
  background-color: #cccccc !important;
  color: #666666 !important;
  border: 1rem solid #cccccc ;
}
.email-button.disabled{
  background-color: #cccccc !important;
  color: #666666 !important;
}
.button.right.fullwidth, .button.left.fullwidth{
  width:100% !important;
}
/* #photo{
  display: none;
} */
.canvas-container {
  width: 972px;
  height: 576px;
}
.frame-canvas {
  width: 972px;
  height: 576px;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;  
}
.logo-img{
  width:100%;
  height:100%;
}
.text{
  width: 100%;
  text-align: center;
  font-size: 2rem !important;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: bold !important;
  margin: 2rem 0 !important;
  font-family: 'Open Sans', sans-serif;
}
.sketching-tools{
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}
.close-screen{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.close-img{
  width:90%;
  height:100%;
}
.close-text{
  width: 100%;
  text-align: center;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: bold !important;
  margin: 5rem 0 !important;
  letter-spacing:.2rem;
  font-family: 'Cinzel Decorative', cursive !important;
}
.close-text span{
  color:#ED1C24;
  font-size: 1.5rem !important;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: bold !important;
  margin: 5rem 0 !important;
  letter-spacing:.2rem;
  font-family: 'Cinzel Decorative', cursive !important;
}
.toolbar--material{
  height: 5vh !important;
  background-color: #ED1C24 !important;
  display:flex;
  align-items: center;
  justify-content: center;
}
.main-container{
  background-color: #fc6 !important;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.email-wrapper{
  width: 90%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
}
.circle-picker{
  justify-content: center;

}

.animated-div {
  animation: move-up 1s ease-in-out;
}

@keyframes move-up {
  0% {
      bottom:-100%;
  }
  100% {
      bottom: 0;
  }
}
#myDiv {
  width: 100%;
  height: 50%;
  position: fixed;
  bottom:0;
  border-radius: 5rem 5rem 0 0;
  background-color: #ED1C24;
  z-index:2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#myDiv h2{
  font-size: 2rem;
  font-weight: bold;
  /* font-family: 'Open Sans', sans-serif !important; */
  font-family: 'Cinzel Decorative', cursive !important;
}
.screen-text{
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 3rem;
}
.options{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin:4rem 0;
}
.option{
  /* padding:2rem 5rem; */
  width: 20rem;
  padding: 2rem 0rem;
  background-color: #fc6;
  border:none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}
.option-text{
  font-size: 2rem !important;
  margin-top: 2rem !important;
  color: #6e1919;
  font-family: 'Open Sans', sans-serif !important;
}
.buttons{
  width:40%;
  margin-top:3rem;
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: white;
  width: 35em;
  height:20em;
  font-size: 1.5rem;
  border-radius: 1em;
  overflow: scroll;
  position: relative;
}
.popup-header{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  border-bottom: 1px solid #666666;
  padding: 1em 2em;
}
.popup-header h2{
  text-align: center;
  font-weight: bold !important;
  font-size: 1.8rem !important;
}
.popup-header .button-close-popup{
  color: #000;
  font-size: 1.8rem;
  
}
.content-popup{
   border-bottom: 1px solid #666666;
   padding: 1em 2em;
}
.content-popup p{
  text-align: justify;
  font-size: 1.5rem !important;
  padding: 1em 0;
}
.popup-buttons{
   display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: flex-start;
  /* padding: 2em 2em; */
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 1em;
  background-color: white;
  border-top: 1px black solid;
}
.agree-btn{
  font-size: 1rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 1rem !important;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.5s;
  width:30%;
  color:#000 !important;
  background-color: #fc6 !important;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #fc6;
  outline: none;
  border-radius: 50px;
  margin-right: 1.5rem;
}
.decline-btn{
  font-size: 1rem !important;
  font-weight: bold !important;
  cursor: pointer !important;
  padding: 1rem !important;
  outline: none;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.5s;
  width:30%;
  color:#000 !important;
  background-color: #ED1C24 !important;
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),10px 10px 15px rgba(70,70,70,0.12);
  border: 1rem solid #ED1C24;
  outline: none;
  border-radius: 50px;
}
.circle-picker {
  background-color: #d5d5d5;
  padding-top: 1rem;
  border-radius: 2rem;
}







